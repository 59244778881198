<template>
  <div class="login-page">
    <el-row type="flex" class="row-height" align="middle" justify="center">
      <el-col :lg="6" :md="9" :xs="20" :sm="9">
        <el-card class="box-card">
          <h1 class="title has-text-centered">Авторизация</h1>
          <login-form />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { messages } from '@/app/shared/services';
import LoginForm from './login-form/login-form.vue';

export default {
  name: 'Login',
  components: { LoginForm },
  data() {
    return {
      items: [{ id: 2 }],
    };
  },
  mounted() {
    if (messages[this.$route.query.message]) {
      this.$notify.error({
        title: 'Ошибка авторизации',
        message: messages[this.$route.query.message],
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.row-height {
  height: 100vh;
}
</style>
