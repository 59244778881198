<template>
  <div class="login-form">
    <el-form
      :ref="formName"
      :model="form"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm main"
      label-position="top"
    >
      <el-form-item prop="email">
        <el-input v-model="form.email" placeholder="Email" type="email" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="form.password"
          show-password
          placeholder="Пароль"
          class="bg-purple-light"
          type="password"
        />
      </el-form-item>
      <div class="d-flex">
        <div class="col">
          <el-form-item prop="isRemember">
            <el-checkbox
              v-model="form.isRemember"
              label="Запомнить меня"
              name="isRemember"
              class="bg-purple-light"
            />
          </el-form-item>
        </div>
        <div class="col button-col">
          <el-form-item>
            <el-button type="primary" :loading="loading" @click="submitForm"> Войти </el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { LOGIN } from '../shared/state/types/action_types';

export default {
  name: 'LoginForm',
  data() {
    return {
      form: {
        email: null,
        password: null,
        isRemember: null,
      },
      formName: 'login-form',
      rules: {
        email: [
          {
            required: true,
            type: 'email',
            message: 'Пожалуйста введите Email',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: 'Пожалуйста введите пароль',
            trigger: 'blur',
          },
          {
            min: 8,
            message: 'Минимальное количество символов 8',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.authState.authState.error,
      loading: (state) => state.authState.authState.loading,
      data: (state) => state.authState.authState.data,
    }),
  },
  methods: {
    ...mapActions({
      login: LOGIN,
    }),
    submitForm() {
      this.$refs[this.formName].validate(async (valid) => {
        if (!valid) {
          return false;
        }
        await this.login(this.form);
        if (this.error) {
          return false;
        }
        await this.$router.push('/dashboard');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  .col {
    flex: 1 1 50%;
  }
  .button-col {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
